import { Environment, ProjectIs } from 'src/app/models/projects.model';

export const environment: Environment = {
  production: false,
  isStage: false,
  coreUrl: '',
  matomoSiteId: 15,
  matomoUrlBase: 'https://wd1.urchinsys.com',
  userId: '',
  project: {
    name: 'Stop Claims',
    abbreviation: ProjectIs.STOP_CLAIMS,
    phone: '(844) 786-7252',
    fax: '',
    email: 'info@stopclaims.com',
    address_line_one: '2828 Colby AVE Suite #102',
    address_line_two: 'Everett, WA 98201-3501',
    url: 'www.stopclaims.com',
    parentProjectAbbreviation: ProjectIs.UTMC,
  },
};
